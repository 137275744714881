import { IAddImageCategoryModel } from '@/store/contracts/imageCategories/imageCategories';
import { ValidationModel } from '@/validation';
import { stringRequiredRule } from '@/validation/common/rules';

export enum AddImageCategoryFieldName {
  Name = 'name'
}

class AddImageCategoryValidationModel extends ValidationModel<IAddImageCategoryModel> {
  constructor () {
    super();

    this.setField(AddImageCategoryFieldName.Name, {
      getter: (model) => model?.CategoryName ?? null,
      rules: [
        stringRequiredRule('Category name is required.')
      ]
    });
  }

  public get modelName (): string {
    return 'AddImageCategory';
  }
}

export default new AddImageCategoryValidationModel();
