
import { defineComponent } from 'vue';
import AsyncContent from '@/components/AsyncContent.vue';
import TopNav from '@/components/TopNav.vue';
import LeftDrawer from '@/components/LeftDrawer.vue';
import { provideStore as provideImageCategoriesStore } from '@/store/imageCategories/imageCategories';

export default defineComponent({
  name: 'ImageCategories',
  components: {
    AsyncContent,
    TopNav,
    LeftDrawer
  },
  setup () {
    provideImageCategoriesStore();
  }
});
