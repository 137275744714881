import { render } from "./ImageCategoryListPage.vue?vue&type=template&id=98b3a556"
import script from "./ImageCategoryListPage.vue?vue&type=script&lang=ts"
export * from "./ImageCategoryListPage.vue?vue&type=script&lang=ts"

import "./ImageCategoryListPage.vue?vue&type=style&index=0&id=98b3a556&lang=scss"
script.render = render

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QTooltip,QInput,QBtn,QFile,QTable,QField});
