import { IImageCategoryListingModel, IServerImageCategoryListingModel } from '@/store/contracts/imageCategories/imageCategories';

export function toApp (serverModel: IServerImageCategoryListingModel): IImageCategoryListingModel {
  return {
    id: serverModel.CategoryId,
    name: serverModel.Name,
    tags: serverModel.Tags,
    isUnassigned: serverModel.IsUnassigned
  };
}
