
import { computed, DeepReadonly, defineComponent, Ref, ref, watch } from 'vue';
import { IImageCategoryListingModel, IAddImageCategoryModel, IExportCategoryModelItem, IExportCategoryModel } from '@/store/contracts/imageCategories/imageCategories';
import { useStore as useImageCategoriesStore } from '@/store/imageCategories/imageCategories';
import ImageCategoryCard from '@/components/imageCategories/ImageCategoryCard.vue';
import { useStore as useValidationStore } from '@/store/validation';
import validationModel, { AddImageCategoryFieldName } from '@/validation/addImageCategoryValidationModel';
import { impl } from '@/utils/impl';
import useQuasar from 'quasar/src/composables/use-quasar.js';import exportFile from 'quasar/src/utils/export-file.js';import QFile from 'quasar/src/components/file/QFile.js';import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { provideStore as provideTagListStore } from '@/store/imageCategories/tagList';

export default defineComponent({
  components: {
    ImageCategoryCard
  },
  async setup () {
    const validationStore = useValidationStore();
    const imageCategoriesStore = useImageCategoriesStore();
    const $q = useQuasar();
    provideTagListStore();

    const imageCategories = computed(() => imageCategoriesStore.state.imageCategories.filter(c => !c.isUnassigned));
    const unassignedImageCategory = computed(() => imageCategoriesStore.state.imageCategories.find(c => c.isUnassigned) as IImageCategoryListingModel);

    const addCategoryInput = ref('');
    const isAdding = ref(false);

    const file: Ref<File | null> = ref(null);

    watch(file, function () {
      importFile(file.value);
    });

    await imageCategoriesStore.loadImageCategories();

    function getRowId (row: IImageCategoryListingModel): string {
      return row.id;
    }

    function fieldIsError (fieldName: AddImageCategoryFieldName): boolean {
      return validationStore.fieldIsError(validationModel.modelName, fieldName);
    }

    function fieldErrorMessage (fieldName : AddImageCategoryFieldName): string {
      return validationStore.fieldErrorMessage(validationModel.modelName, fieldName);
    }

    function clearAddCategory () {
      addCategoryInput.value = '';
      validationStore.clear(validationModel);
    }

    async function startAddCategory () {
      isAdding.value = true;
      const model = impl<IAddImageCategoryModel>({
        CategoryName: addCategoryInput.value,
        Tags: []
      });
      await imageCategoriesStore.addCategory(model, {
        onSuccess: async () => {
          addCategoryInput.value = '';
          // Don't clear validationStore here because we want to throw error for duplicate values.
        }
      });
      isAdding.value = false;
    }

    async function onTagsMoved (id: string, tagsAdded: string[]) {
      await imageCategoriesStore.moveImageTags(tagsAdded, id);
    }

    function confirmDelete (row: IImageCategoryListingModel) {
      $q.dialog({
        title: 'Delete Confirmation',
        message: `Are you sure you want to delete ${row.name}?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        imageCategoriesStore.deleteImageCategory(row.id);
      });
    }

    function mapCategory (category: DeepReadonly<IImageCategoryListingModel>): IExportCategoryModelItem {
      return {
        Name: category.name,
        Tags: category.tags
      };
    }

    function exportCategories () {
      const categoryItemsForExport = imageCategories.value.map(mapCategory);
      const categoriesForExport = {
        Categories: categoryItemsForExport
      } as IExportCategoryModel;
      const exportString = JSON.stringify(categoriesForExport);
      const status = exportFile('imageCategoriesExport.json', exportString, 'application/json');
      if (!status) {
        $q.notify({
          type: 'negative',
          message: 'Unable to export file. Click here to copy contents to clipboard.',
          actions: [{
            icon: 'mdi-clipboard-outline',
            color: 'white',
            handler: () => {
              copyToClipboard(exportString);
              $q.notify({
                type: 'positive',
                message: 'Copied!',
                timeout: 3000
              });
            }
          }]
        });
      }
    }

    const isImporting = ref(false);

    async function importFile (importFile: File | null) {
      if (importFile) {
        isImporting.value = true;
        await imageCategoriesStore.importTags(importFile);
        file.value = null;
        isImporting.value = false;
      }
    }
    const fileInput = ref<QFile>();
    function pickFile () {
      fileInput.value!.pickFiles();
    }

    return {
      getRowId,
      imageCategories,
      unassignedImageCategory,
      onTagsMoved,
      confirmDelete,
      startAddCategory,
      clearAddCategory,
      AddImageCategoryFieldName,
      fieldIsError,
      fieldErrorMessage,
      addCategoryInput,
      isAdding,
      exportCategories,
      file,
      pickFile,
      fileInput,
      isImporting
    };
  }
});
