
import { IImageCategoryListingModel } from '@/store/contracts/imageCategories/imageCategories';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    imageCategory: {
      required: true,
      type: Object as PropType<IImageCategoryListingModel>
    }
  },
  setup (props, { emit }) {
    const isOpen = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v)
    });

    async function close () {
      isOpen.value = false;
    }

    const tagsDisplay = computed(() => props.imageCategory.tags.join(', '));

    return {
      isOpen,
      close,
      tagsDisplay
    };
  }
});
