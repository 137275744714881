<template>
  <q-card class="o-image-category-card" :style="cardStyle">
    <q-card-section class="o-image-category-card__header"
      horizontal>
      <q-input
        ref="cardInput"
        class="o-image-category-card__header__input -name"
        type="text"
        label="Category Name"
        dense
        @keyup.enter="onKeyEnter"
        @keyup.esc="stopRenaming"
        :readonly="!isRenaming"
        v-model="categoryNameText"
        :error="fieldIsError(AddImageCategoryFieldName.Name)"
        :error-message="fieldErrorMessage(AddImageCategoryFieldName.Name)"
      >
        <template v-slot:after>
          <EditFieldActions
            :isUpdating="isRenaming"
            :isRequestingUpdate="isRequestingRename"
            @do-update="doRename"
            @start-updating="startRenaming"
            @stop-updating="stopRenaming"
          />
        </template>
      </q-input>
      <q-btn
        v-if="showAllVisible"
        class="o-image-category-card__header__action -showAll"
        size="sm"
        icon="mdi-dots-horizontal"
        round
        dense
        color="pl-navy-900"
        flat
        @click="showAll"
      >
        <q-tooltip>Show All Tags</q-tooltip>
      </q-btn>
      <q-btn
        v-if="!imageCategory.isUnassigned"
        class="o-image-category-card__header__action -delete"
        size="sm"
        icon="mdi-delete-outline"
        round
        dense
        color="pl-navy-900"
        flat
        @click="emitDelete"
      />
      <q-icon
        v-else
        class="o-image-category-card__header__info"
        name="mdi-information-outline"
      >
        <q-tooltip>
          This is the Unassigned category.  Any new tags will end up here.
          <br/>
          If you delete a category, the tags on that category will be moved here.
          <br/>
          You cannot delete this category.
        </q-tooltip>
      </q-icon>
    </q-card-section>
    <div class="o-image-category-card__tags">
      <q-card-section
        :data-category="imageCategory.name"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
        @dragend="onDragEnd"
        @drop="onDrop"
        @click="onDropzoneClick"
        :class="categoryHtmlClass">
        <q-scroll-area :style="scrollAreaStyle">
          <q-badge v-for="tag in imageCategory.tags" :key="tag"
            :data-tag="tag"
            class="o-image-category-card__tags__tag"
            draggable="true"
            @dragstart="onDragStart($event, tag)"
            @dragenter="onDragEnterTag"
            @dragleave="onDragLeaveTag"
            @dragend="onDragEnd"
            @dragover="onDragOverTag"
            @drop="onDropTag"
            @click="addToMove($event, tag)"
            @click.right="rightAddToMove($event, tag)"
            :id="tagHtmlId(tag)"
            :class="tagHtmlClass(tag)"
            >
            {{ tag }}
            <q-menu
              context-menu
              touch-position
            >
              <q-item clickable dense>
                <q-item-section>Move to</q-item-section>
                <q-item-section side>
                  <q-icon name="mdi-chevron-right" />
                </q-item-section>
                <q-menu
                  auto-close
                  anchor="top end"
                  self="top start"
                >
                  <q-list dense>
                    <q-item
                      v-for="cat in imageCategories"
                      :key="cat.id"
                      clickable
                      dense
                      @click="moveTags(cat.id)"
                    >
                      <q-item-section>{{ cat.name }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>
            </q-menu>
          </q-badge>
        </q-scroll-area>
      </q-card-section>
    </div>
  </q-card>
  <ImageCategoryDialog v-model="isShowingAll" :imageCategory="imageCategory"/>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { IImageCategoryListingModel, IAddImageCategoryModel } from '@/store/contracts/imageCategories/imageCategories';
import validationModel, { AddImageCategoryFieldName } from '@/validation/addImageCategoryValidationModel';
import { useStore as useImageCategoriesStore } from '@/store/imageCategories/imageCategories';
import { useStore as useTagListStore } from '@/store/imageCategories/tagList';
import { QInput } from 'quasar';
import EditFieldActions from '@/components/EditFieldActions.vue';
import { IIndexable } from '@/utils/indexable';
import { useFieldValidation } from '@/components/utils/fieldValidation';
import { useInlineEditable } from '@/components/utils/inlineEditable';
import { impl } from '@/utils/impl';
import ImageCategoryDialog from '@/components/imageCategories/ImageCategoryDialog.vue';

export default defineComponent({
  components: {
    EditFieldActions,
    ImageCategoryDialog
  },
  props: {
    imageCategory: {
      required: true,
      type: Object as PropType<IImageCategoryListingModel>
    }
  },
  emits: ['delete', 'tags-added'],
  setup (props, { emit }) {
    const cardInput = ref<QInput | null>(null);
    const cardInputEl = computed(() => (cardInput.value?.$el ?? null) as HTMLElement | null);
    const cardInputInputEl = computed(() => cardInputEl.value?.querySelector('input') ?? null);

    const imageCategoriesStore = useImageCategoriesStore();
    const tagListStore = useTagListStore();
    const cardValidationModel = validationModel.createForUUID(props.imageCategory.id);

    const {
      compText: categoryNameText,
      isEditing: isRenaming,
      isRequestingUpdate: isRequestingRename,
      doUpdate: doRename,
      startEditing: startRenaming,
      stopEditing: stopRenaming
    } = useInlineEditable(
      () => props.imageCategory.name,
      computed(() => props.imageCategory.name),
      cardInputInputEl,
      async (comp) => {
        await imageCategoriesStore.renameImageCategory({
          categoryId: props.imageCategory.id,
          name: comp.editText.value
        }, {
          onSuccess: async () => stopRenaming()
        });
      },
      undefined,
      async () => {
        validationStore.clear(cardValidationModel);
      }
    );

    const {
      validationStore,
      fieldIsError,
      fieldErrorMessage
    } = useFieldValidation<AddImageCategoryFieldName, IAddImageCategoryModel>(cardValidationModel, isRenaming);

    const cardStyle = props.imageCategory.isUnassigned ? 'min-height: 325px;max-height: 325px' : 'min-width: 250px;max-width: 250px;';
    const scrollAreaStyle = props.imageCategory.isUnassigned ? 'height: 245px' : 'height: 195px;width: 230px';
    const imageCategories = computed(() => imageCategoriesStore.state.imageCategories.filter(c => c !== props.imageCategory));

    const showAllVisible = computed(() => props.imageCategory.tags.length > 0);

    const isShowingAll = ref(false);

    function showAll () {
      isShowingAll.value = true;
    }

    function onDragStart (e: DragEvent, tag: string) {
      if (e.dataTransfer !== null && e.target !== null) {
        e.dataTransfer.setData('text', (e.target as HTMLElement).id);
        e.dataTransfer.dropEffect = 'move';
        dragAddToMove(e, tag);
        tagListStore.startDrag();
      }
    }

    function onDragEnter (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragEnterTag (e) {
      e.stopPropagation();
      if (tagListStore.state.isDragging) {
        (e.target.parentNode as HTMLElement).dataset.onTag = 'true';
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragOver (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragOverTag (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        (e.target.parentNode as HTMLElement).dataset.onTag = 'true';
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragLeave (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        if ((e.target as HTMLElement).dataset.onTag !== 'true') {
          tagListStore.deselectDropTarget(props.imageCategory);
        }
      }
    }

    function onDragLeaveTag (e) {
      e.stopPropagation();
      if (tagListStore.state.isDragging) {
        (e.target.parentNode as HTMLElement).dataset.onTag = 'false';
      }
    }

    function onDrop (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        moveTags(props.imageCategory.id);
        tagListStore.setDropTarget(null);
      }
      tagListStore.stopDrag();
    }

    function onDropTag (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        moveTags(props.imageCategory.id);
        tagListStore.setDropTarget(null);
      }
      tagListStore.stopDrag();
    }

    function onDragEnd (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        tagListStore.setDropTarget(null);
      }
      tagListStore.stopDrag();
    }

    function tagHtmlId (tag: string) {
      return `image-tag-${tag}`;
    }

    function emitDelete () {
      emit('delete');
    }

    function onKeyEnter () {
      if (isRenaming.value) {
        doRename();
      }
    }

    const tagsToMove = computed(() => tagListStore.state.selectedTags);

    function tagHtmlClass (tag: string): IIndexable<boolean> {
      return {
        '-selected': tagsToMove.value.includes(tag),
        '-unselected': !tagsToMove.value.includes(tag)
      };
    }

    const categoryHtmlClass = computed(() => impl<IIndexable<boolean>>({
      '-drag-enter': tagListStore.state.dropTargetCategory?.id === props.imageCategory.id
    }));

    // Method below takes in a click event, and if CTRL key is not being pressed it will switch the selected tag to the one that was clicked.
    // Else-if and Else statemtents effectively toggle whether an item is being added or not.
    // Else-if statement then checks if the target element has the class 'selected', if so it removes it.
    // Else statement adds the targetted tag to the list of tags to move since it is not already in it.
    function addToMove (e: MouseEvent, tag: string) {
      e.stopPropagation();
      if (!e.ctrlKey) {
        clearMoveList();
        tagListStore.addTag(tag);
      } else if (tagsToMove.value.includes(tag)) {
        tagListStore.removeTag(tag);
      } else {
        tagListStore.addTag(tag);
      }
    }

    function dragAddToMove (e: DragEvent, tag: string) {
      if (!e.ctrlKey && !tagsToMove.value.includes(tag)) {
        clearMoveList();
      }

      tagListStore.addTag(tag);
    }

    function rightAddToMove (e, tag: string) {
      if (!e.ctrlKey && !tagsToMove.value.includes(tag)) {
        clearMoveList();
        tagListStore.addTag(tag);
      } else {
        tagListStore.addTag(tag);
      }
    }

    function clearMoveList (e?: Event) {
      tagListStore.clearTags();
      if (e !== undefined) {
        e.preventDefault();
      }
    }

    function onDropzoneClick (e: MouseEvent) {
      if (!e.ctrlKey) {
        clearMoveList(e);
      }
    }

    function moveTags (categoryId: string) {
      emit('tags-added', categoryId, tagsToMove.value);
      clearMoveList();
    }

    return {
      cardStyle,
      scrollAreaStyle,
      imageCategories,
      cardInput,
      onDragStart,
      onDragEnter,
      onDragEnterTag,
      onDragOver,
      onDragOverTag,
      onDragLeave,
      onDragLeaveTag,
      onDrop,
      onDropTag,
      onDragEnd,
      tagHtmlId,
      tagHtmlClass,
      emitDelete,
      isRenaming,
      categoryNameText,
      startRenaming,
      stopRenaming,
      doRename,
      isRequestingRename,
      AddImageCategoryFieldName,
      fieldIsError,
      fieldErrorMessage,
      onKeyEnter,
      addToMove,
      rightAddToMove,
      clearMoveList,
      moveTags,
      categoryHtmlClass,
      onDropzoneClick,
      showAllVisible,
      isShowingAll,
      showAll
    };
  }
});
</script>

<style lang="scss">

.o-image-category-card {
  min-height: 275px;
  max-height: 275px;
  margin: 5px;
  display: flex;
  flex-direction: column;

  .q-field--with-bottom {
    padding-bottom: 0px;
  }

  &__header {
    display: flex;
    justify-content: left;
    padding-top: 8px;
    margin: 0px 8px;
    font-weight: bold;

    &__action {
      margin: 4px 0px 0px 0px;
    }

    &__info {
      margin: 16px 0px 0px 4px;
    }
  }

  &__tags {
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;

    &__tag {

      &.-selected {
        border: 3px solid black;
      }
      &.-unselected {
        border: 3px solid transparent;
      }
    }

    &__tag+&__tag {
      margin: 2px;
    }

    .q-card__section {
      height: 100%;
      &.-drag-enter {
        border-style: dashed;
      }
    }
  }
}

</style>
