<template>
  <q-layout id="image-category-view bg-pl-gray-50" view="lhh lpR lfr">
        <TopNav id="top-nav"></TopNav>
        <LeftDrawer id="side-nav"></LeftDrawer>
        <AsyncContent id="view-content"></AsyncContent>
    </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AsyncContent from '@/components/AsyncContent.vue';
import TopNav from '@/components/TopNav.vue';
import LeftDrawer from '@/components/LeftDrawer.vue';
import { provideStore as provideImageCategoriesStore } from '@/store/imageCategories/imageCategories';

export default defineComponent({
  name: 'ImageCategories',
  components: {
    AsyncContent,
    TopNav,
    LeftDrawer
  },
  setup () {
    provideImageCategoriesStore();
  }
});
</script>
