import { Settings } from '@/settings';
import { IAddImageCategoryModel, IAddImageCategoryModelRequest, IImageCategoriesState, IImageCategoriesStore, IServerAddCategoryResponse, IServerImageCategoryResponse, TAddCategory, TDeleteImageCategory, TImportTags, TLoadImageCategories, TMoveImageTags, TRenameImageCategory, TSetImageCategories } from '@/store/contracts/imageCategories/imageCategories';
import { ImageCategoryModifyType } from '@/store/contracts/imageCategories/imageCategoryModifyType';
import { toApp as imageCategoryToApp } from '@/store/imageCategories/mapping/imageCategoryListing';
import { chainHandlers, DefaultIStatusHandler, handleErrorStatus, handleErrorStatusResult, ResponseStatus } from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { App, inject, provide, reactive, readonly } from 'vue';
import { store as validationStore } from '@/store/validation';
import validationModel, { AddImageCategoryFieldName } from '@/validation/addImageCategoryValidationModel';
import { impl } from '@/utils/impl';
import { isNullOrWhitespace } from '@/utils/stringUtils';

const ImageCategoriesStoreKey = Symbol('ImageCategoriesStore');

function setImageCategories (state: IImageCategoriesState): TSetImageCategories {
  return (imageCategories) => {
    state.imageCategories = imageCategories.sortBy(c => c, (c1, c2) => {
      return c1.name.localeCompare(c2.name, 'en', { sensitivity: 'base' });
    });
  };
}

function loadImageCategories (state: IImageCategoriesState): TLoadImageCategories {
  return async (handler) => {
    await handleErrorStatusResult(await sendRequest<IServerImageCategoryResponse>(
      `${Settings.adminApiBaseUrl}/admin/v1/category/load`,
      'get'
    ), chainHandlers({
      onSuccess: async (c) => setImageCategories(state)(c.Categories.map(imageCategoryToApp))
    }, handler));
  };
}

function renameCategory (state: IImageCategoriesState): TRenameImageCategory {
  return async (model, handler) => {
    const renameValidationModel = validationModel.createForUUID(model.categoryId);
    const isValid = await validationStore.doValidation({
      model: renameValidationModel,
      value: impl<IAddImageCategoryModel>({
        CategoryName: model.name,
        Tags: []
      })
    });
    if (isValid) {
      await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
        `${Settings.adminApiBaseUrl}/admin/v1/category/modify`,
        'post',
        {
          $type: ImageCategoryModifyType.RenameCategory,
          CategoryId: model.categoryId,
          NewName: model.name
        }
      ), chainHandlers({
        onSuccess: async () => await loadImageCategories(state)(),
        onError: async (r) => {
          const status = r?.status ?? 500;
          if (status === 409) {
            validationStore.setModelFieldResult(renameValidationModel.modelName, AddImageCategoryFieldName.Name, `Category with name ${model.name} exists.`);
            return;
          }
          await DefaultIStatusHandler.onError(r);
        }
      }, handler));
    }
  };
}

function deleteCategory (state: IImageCategoriesState): TDeleteImageCategory {
  return async (categoryId, handler) => {
    await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
      `${Settings.adminApiBaseUrl}/admin/v1/category/modify`,
      'post',
      {
        $type: ImageCategoryModifyType.DeleteCategory,
        CategoryId: categoryId
      }
    ), chainHandlers({
      onSuccess: async () => await loadImageCategories(state)()
    }, handler));
  };
}

function addCategory (state: IImageCategoriesState): TAddCategory {
  return async (model, handler) => {
    const isValid = await validationStore.doValidation({
      model: validationModel,
      value: model
    });
    if (isValid) {
      await handleErrorStatus(await sendRequest<IServerAddCategoryResponse>(
        `${Settings.adminApiBaseUrl}/admin/v1/category/add`,
        'post',
        {
          Categories: [{
            CategoryName: model.CategoryName,
            Tags: []
          }]
        } as IAddImageCategoryModelRequest
      ), chainHandlers({
        onSuccess: async (c) => {
          if (c.CategoryIds.length > 0) {
            await loadImageCategories(state)();
          } else {
            validationStore.setModelFieldResult(validationModel.modelName, AddImageCategoryFieldName.Name, `Category with name ${model.CategoryName} exists.`);
          }
        }
      }, handler));
    }
  };
}

function moveImageTags (state: IImageCategoriesState): TMoveImageTags {
  return async (tags, targetCategoryId, handler) => {
    const targetCategory = state.imageCategories
      .find((c) => c.id === targetCategoryId);
    if (targetCategory) {
      const tagsToMove = tags.filter((t) => !targetCategory.tags.includes(t));
      if (tagsToMove.length > 0) {
        targetCategory.tags = [...targetCategory.tags, ...tagsToMove];
        state.imageCategories
          .filter((c) => c.tags.some(t => tagsToMove.includes(t)))
          .forEach((sc) => {
            sc.tags = sc.tags.filter((t) => !tagsToMove.includes(t));
          });
        await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
          `${Settings.adminApiBaseUrl}/admin/v1/category/modify`,
          'post',
          {
            $type: ImageCategoryModifyType.MoveTags,
            CategoryId: targetCategoryId,
            Tags: tagsToMove
          }
        ), chainHandlers({
          onSuccess: async () => await loadImageCategories(state)()
        }, handler));
      }
    }
  };
}

function importTags (state: IImageCategoriesState): TImportTags {
  return async (file, handler) => {
    const blob = new Blob([file]);
    await handleErrorStatus(await sendRequest<ResponseStatus.Success>(
      `${Settings.adminApiBaseUrl}/admin/v1/category/import`,
      'post',
      blob,
      async (config) => {
        const contentType = isNullOrWhitespace(blob.type) ? file.type : blob.type;
        config.headers = config.headers ?? {};
        config.headers['Content-Type'] = contentType;
        return config;
      }
    ), chainHandlers({
      onSuccess: async () => await loadImageCategories(state)()
    }, handler));
  };
}

export function createState (): IImageCategoriesState {
  validationStore.clear(validationModel);
  return reactive<IImageCategoriesState>({
    imageCategories: []
  });
}

export function createForState (state: IImageCategoriesState): IImageCategoriesStore {
  return {
    state: readonly(state),
    setImageCategories: setImageCategories(state),
    loadImageCategories: loadImageCategories(state),
    addCategory: addCategory(state),
    deleteImageCategory: deleteCategory(state),
    moveImageTags: moveImageTags(state),
    renameImageCategory: renameCategory(state),
    importTags: importTags(state)
  };
}

export function provideStore (app?: App<Element>): IImageCategoriesStore {
  const state = createState();
  if (app !== undefined) {
    app.provide(ImageCategoriesStoreKey, state);
  } else {
    provide(ImageCategoriesStoreKey, state);
  }

  return createForState(state);
}

export function useStore (): IImageCategoriesStore {
  const state = inject<IImageCategoriesState>(ImageCategoriesStoreKey);
  if (state === undefined) {
    throw new Error('Using ImageCategoriesStore before providing it!');
  }
  return createForState(state);
}
