<template>
  <q-dialog
    v-model="isOpen"
    persistent
    >
    <q-card class="o-image-category-dialog">
      <q-card-section class="m-dialog-header">
        <div class="m-dialog-header__title text-pl-navy-900"> {{ imageCategory.name }} </div>
      </q-card-section>
      <q-card-section class="m-dialog__content">
        <div class="m-dialog"> {{ tagsDisplay }}</div>
      </q-card-section>
      <q-card-section class="m-dialog__actions row justify-end items-center">
        <q-btn
          class="o-image-category-dialog__action -close"
          label="Close"
          outline
          color="pl-navy-900"
          @click="close"/>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { IImageCategoryListingModel } from '@/store/contracts/imageCategories/imageCategories';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    imageCategory: {
      required: true,
      type: Object as PropType<IImageCategoryListingModel>
    }
  },
  setup (props, { emit }) {
    const isOpen = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v)
    });

    async function close () {
      isOpen.value = false;
    }

    const tagsDisplay = computed(() => props.imageCategory.tags.join(', '));

    return {
      isOpen,
      close,
      tagsDisplay
    };
  }
});
</script>

<style lang="scss">
.o-image-category-dialog {
  margin: 20px;
  border-radius: 15px;
  min-width: 50vw;

  &__field+&__field {
    margin-top: 16px;
  }

  &__action+&__action {
    margin-left: 8px;
  }

  .m-dialog-header {
    &__title {
      font-size: 20px;
    }
  }
}
</style>
