
import { computed, defineComponent, PropType, ref } from 'vue';
import { IImageCategoryListingModel, IAddImageCategoryModel } from '@/store/contracts/imageCategories/imageCategories';
import validationModel, { AddImageCategoryFieldName } from '@/validation/addImageCategoryValidationModel';
import { useStore as useImageCategoriesStore } from '@/store/imageCategories/imageCategories';
import { useStore as useTagListStore } from '@/store/imageCategories/tagList';
import QInput from 'quasar/src/components/input/QInput.js';;
import EditFieldActions from '@/components/EditFieldActions.vue';
import { IIndexable } from '@/utils/indexable';
import { useFieldValidation } from '@/components/utils/fieldValidation';
import { useInlineEditable } from '@/components/utils/inlineEditable';
import { impl } from '@/utils/impl';
import ImageCategoryDialog from '@/components/imageCategories/ImageCategoryDialog.vue';

export default defineComponent({
  components: {
    EditFieldActions,
    ImageCategoryDialog
  },
  props: {
    imageCategory: {
      required: true,
      type: Object as PropType<IImageCategoryListingModel>
    }
  },
  emits: ['delete', 'tags-added'],
  setup (props, { emit }) {
    const cardInput = ref<QInput | null>(null);
    const cardInputEl = computed(() => (cardInput.value?.$el ?? null) as HTMLElement | null);
    const cardInputInputEl = computed(() => cardInputEl.value?.querySelector('input') ?? null);

    const imageCategoriesStore = useImageCategoriesStore();
    const tagListStore = useTagListStore();
    const cardValidationModel = validationModel.createForUUID(props.imageCategory.id);

    const {
      compText: categoryNameText,
      isEditing: isRenaming,
      isRequestingUpdate: isRequestingRename,
      doUpdate: doRename,
      startEditing: startRenaming,
      stopEditing: stopRenaming
    } = useInlineEditable(
      () => props.imageCategory.name,
      computed(() => props.imageCategory.name),
      cardInputInputEl,
      async (comp) => {
        await imageCategoriesStore.renameImageCategory({
          categoryId: props.imageCategory.id,
          name: comp.editText.value
        }, {
          onSuccess: async () => stopRenaming()
        });
      },
      undefined,
      async () => {
        validationStore.clear(cardValidationModel);
      }
    );

    const {
      validationStore,
      fieldIsError,
      fieldErrorMessage
    } = useFieldValidation<AddImageCategoryFieldName, IAddImageCategoryModel>(cardValidationModel, isRenaming);

    const cardStyle = props.imageCategory.isUnassigned ? 'min-height: 325px;max-height: 325px' : 'min-width: 250px;max-width: 250px;';
    const scrollAreaStyle = props.imageCategory.isUnassigned ? 'height: 245px' : 'height: 195px;width: 230px';
    const imageCategories = computed(() => imageCategoriesStore.state.imageCategories.filter(c => c !== props.imageCategory));

    const showAllVisible = computed(() => props.imageCategory.tags.length > 0);

    const isShowingAll = ref(false);

    function showAll () {
      isShowingAll.value = true;
    }

    function onDragStart (e: DragEvent, tag: string) {
      if (e.dataTransfer !== null && e.target !== null) {
        e.dataTransfer.setData('text', (e.target as HTMLElement).id);
        e.dataTransfer.dropEffect = 'move';
        dragAddToMove(e, tag);
        tagListStore.startDrag();
      }
    }

    function onDragEnter (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragEnterTag (e) {
      e.stopPropagation();
      if (tagListStore.state.isDragging) {
        (e.target.parentNode as HTMLElement).dataset.onTag = 'true';
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragOver (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragOverTag (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        (e.target.parentNode as HTMLElement).dataset.onTag = 'true';
        tagListStore.setDropTarget(props.imageCategory);
      }
    }

    function onDragLeave (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        if ((e.target as HTMLElement).dataset.onTag !== 'true') {
          tagListStore.deselectDropTarget(props.imageCategory);
        }
      }
    }

    function onDragLeaveTag (e) {
      e.stopPropagation();
      if (tagListStore.state.isDragging) {
        (e.target.parentNode as HTMLElement).dataset.onTag = 'false';
      }
    }

    function onDrop (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        moveTags(props.imageCategory.id);
        tagListStore.setDropTarget(null);
      }
      tagListStore.stopDrag();
    }

    function onDropTag (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        moveTags(props.imageCategory.id);
        tagListStore.setDropTarget(null);
      }
      tagListStore.stopDrag();
    }

    function onDragEnd (e) {
      e.preventDefault();
      if (tagListStore.state.isDragging) {
        tagListStore.setDropTarget(null);
      }
      tagListStore.stopDrag();
    }

    function tagHtmlId (tag: string) {
      return `image-tag-${tag}`;
    }

    function emitDelete () {
      emit('delete');
    }

    function onKeyEnter () {
      if (isRenaming.value) {
        doRename();
      }
    }

    const tagsToMove = computed(() => tagListStore.state.selectedTags);

    function tagHtmlClass (tag: string): IIndexable<boolean> {
      return {
        '-selected': tagsToMove.value.includes(tag),
        '-unselected': !tagsToMove.value.includes(tag)
      };
    }

    const categoryHtmlClass = computed(() => impl<IIndexable<boolean>>({
      '-drag-enter': tagListStore.state.dropTargetCategory?.id === props.imageCategory.id
    }));

    // Method below takes in a click event, and if CTRL key is not being pressed it will switch the selected tag to the one that was clicked.
    // Else-if and Else statemtents effectively toggle whether an item is being added or not.
    // Else-if statement then checks if the target element has the class 'selected', if so it removes it.
    // Else statement adds the targetted tag to the list of tags to move since it is not already in it.
    function addToMove (e: MouseEvent, tag: string) {
      e.stopPropagation();
      if (!e.ctrlKey) {
        clearMoveList();
        tagListStore.addTag(tag);
      } else if (tagsToMove.value.includes(tag)) {
        tagListStore.removeTag(tag);
      } else {
        tagListStore.addTag(tag);
      }
    }

    function dragAddToMove (e: DragEvent, tag: string) {
      if (!e.ctrlKey && !tagsToMove.value.includes(tag)) {
        clearMoveList();
      }

      tagListStore.addTag(tag);
    }

    function rightAddToMove (e, tag: string) {
      if (!e.ctrlKey && !tagsToMove.value.includes(tag)) {
        clearMoveList();
        tagListStore.addTag(tag);
      } else {
        tagListStore.addTag(tag);
      }
    }

    function clearMoveList (e?: Event) {
      tagListStore.clearTags();
      if (e !== undefined) {
        e.preventDefault();
      }
    }

    function onDropzoneClick (e: MouseEvent) {
      if (!e.ctrlKey) {
        clearMoveList(e);
      }
    }

    function moveTags (categoryId: string) {
      emit('tags-added', categoryId, tagsToMove.value);
      clearMoveList();
    }

    return {
      cardStyle,
      scrollAreaStyle,
      imageCategories,
      cardInput,
      onDragStart,
      onDragEnter,
      onDragEnterTag,
      onDragOver,
      onDragOverTag,
      onDragLeave,
      onDragLeaveTag,
      onDrop,
      onDropTag,
      onDragEnd,
      tagHtmlId,
      tagHtmlClass,
      emitDelete,
      isRenaming,
      categoryNameText,
      startRenaming,
      stopRenaming,
      doRename,
      isRequestingRename,
      AddImageCategoryFieldName,
      fieldIsError,
      fieldErrorMessage,
      onKeyEnter,
      addToMove,
      rightAddToMove,
      clearMoveList,
      moveTags,
      categoryHtmlClass,
      onDropzoneClick,
      showAllVisible,
      isShowingAll,
      showAll
    };
  }
});
