<template>
  <div class="o-image-categories-listing">
    <div class="m-pageHeader">
      <div class="m-pageHeader__title -image-categories-listing text-pl-black text-body2">
        <span>Image Categories</span>
        <q-icon
          class="m-pageHeader__title__info"
          name="mdi-information-outline"
        >
          <q-tooltip>
            When moving tags, you may select multiple by holding 'ctrl'.
            <br/>
            Tags may be moved via drag+drop or by right-clicking.
          </q-tooltip>
        </q-icon>
      </div>
      <div class="m-pageHeader__actions">
        <q-input
          class="m-pageHeader__actions__action -add_field"
          label="Add Category"
          dense
          clearable
          @clear="clearAddCategory"
          clear-icon="mdi-close"
          v-model="addCategoryInput"
          type="text"
          @keyup.enter="startAddCategory"
          @keyup.esc="clearAddCategory"
          :error="fieldIsError(AddImageCategoryFieldName.Name)"
          :error-message="fieldErrorMessage(AddImageCategoryFieldName.Name)"
        >
          <template v-slot:after>
            <q-btn
              :loading="isAdding"
              class="a-addCategory__start"
              label="Add"
              outline
              color="pl-navy-900"
              @click="startAddCategory"
            />
          </template>
        </q-input>
        <q-file
          ref="fileInput"
          v-model="file"
          style="display: none"
          accept="application/json"
        />
        <q-btn
          class="m-pageHeader__actions__action -import"
          color="pl-navy-900"
          label="Import Categories"
          outline
          :loading="isImporting"
          @click="pickFile"
        />
        <q-btn
          class="m-pageHeader__actions__action -export"
          label="Export Categories"
          color="pl-navy-900"
          outline
          @click="exportCategories"
        />
      </div>
    </div>
    <div class="m-pageContent">
      <ImageCategoryCard :imageCategory="unassignedImageCategory" @tags-added="(newCatId, addedTags) => onTagsMoved(newCatId, addedTags)"/>
    </div>
    <div class="m-pageContent">
      <q-table
        class="o-image-categories-listing__table"
        :rows="imageCategories"
        :grid="true"
        :row-key="getRowId"
        :pagination="{
          page: 1,
          rowsPerPage: 0
        }"
      >
        <template v-slot:item="props">
          <ImageCategoryCard
            :key="props.row.id"
            :imageCategory="props.row"
            @tags-added="(newCatId, addedTags) => onTagsMoved(newCatId, addedTags)"
            @delete="confirmDelete(props.row)"
          />
        </template>
      </q-table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent, Ref, ref, watch } from 'vue';
import { IImageCategoryListingModel, IAddImageCategoryModel, IExportCategoryModelItem, IExportCategoryModel } from '@/store/contracts/imageCategories/imageCategories';
import { useStore as useImageCategoriesStore } from '@/store/imageCategories/imageCategories';
import ImageCategoryCard from '@/components/imageCategories/ImageCategoryCard.vue';
import { useStore as useValidationStore } from '@/store/validation';
import validationModel, { AddImageCategoryFieldName } from '@/validation/addImageCategoryValidationModel';
import { impl } from '@/utils/impl';
import { useQuasar, exportFile, QFile, copyToClipboard } from 'quasar';
import { provideStore as provideTagListStore } from '@/store/imageCategories/tagList';

export default defineComponent({
  components: {
    ImageCategoryCard
  },
  async setup () {
    const validationStore = useValidationStore();
    const imageCategoriesStore = useImageCategoriesStore();
    const $q = useQuasar();
    provideTagListStore();

    const imageCategories = computed(() => imageCategoriesStore.state.imageCategories.filter(c => !c.isUnassigned));
    const unassignedImageCategory = computed(() => imageCategoriesStore.state.imageCategories.find(c => c.isUnassigned) as IImageCategoryListingModel);

    const addCategoryInput = ref('');
    const isAdding = ref(false);

    const file: Ref<File | null> = ref(null);

    watch(file, function () {
      importFile(file.value);
    });

    await imageCategoriesStore.loadImageCategories();

    function getRowId (row: IImageCategoryListingModel): string {
      return row.id;
    }

    function fieldIsError (fieldName: AddImageCategoryFieldName): boolean {
      return validationStore.fieldIsError(validationModel.modelName, fieldName);
    }

    function fieldErrorMessage (fieldName : AddImageCategoryFieldName): string {
      return validationStore.fieldErrorMessage(validationModel.modelName, fieldName);
    }

    function clearAddCategory () {
      addCategoryInput.value = '';
      validationStore.clear(validationModel);
    }

    async function startAddCategory () {
      isAdding.value = true;
      const model = impl<IAddImageCategoryModel>({
        CategoryName: addCategoryInput.value,
        Tags: []
      });
      await imageCategoriesStore.addCategory(model, {
        onSuccess: async () => {
          addCategoryInput.value = '';
          // Don't clear validationStore here because we want to throw error for duplicate values.
        }
      });
      isAdding.value = false;
    }

    async function onTagsMoved (id: string, tagsAdded: string[]) {
      await imageCategoriesStore.moveImageTags(tagsAdded, id);
    }

    function confirmDelete (row: IImageCategoryListingModel) {
      $q.dialog({
        title: 'Delete Confirmation',
        message: `Are you sure you want to delete ${row.name}?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        imageCategoriesStore.deleteImageCategory(row.id);
      });
    }

    function mapCategory (category: DeepReadonly<IImageCategoryListingModel>): IExportCategoryModelItem {
      return {
        Name: category.name,
        Tags: category.tags
      };
    }

    function exportCategories () {
      const categoryItemsForExport = imageCategories.value.map(mapCategory);
      const categoriesForExport = {
        Categories: categoryItemsForExport
      } as IExportCategoryModel;
      const exportString = JSON.stringify(categoriesForExport);
      const status = exportFile('imageCategoriesExport.json', exportString, 'application/json');
      if (!status) {
        $q.notify({
          type: 'negative',
          message: 'Unable to export file. Click here to copy contents to clipboard.',
          actions: [{
            icon: 'mdi-clipboard-outline',
            color: 'white',
            handler: () => {
              copyToClipboard(exportString);
              $q.notify({
                type: 'positive',
                message: 'Copied!',
                timeout: 3000
              });
            }
          }]
        });
      }
    }

    const isImporting = ref(false);

    async function importFile (importFile: File | null) {
      if (importFile) {
        isImporting.value = true;
        await imageCategoriesStore.importTags(importFile);
        file.value = null;
        isImporting.value = false;
      }
    }
    const fileInput = ref<QFile>();
    function pickFile () {
      fileInput.value!.pickFiles();
    }

    return {
      getRowId,
      imageCategories,
      unassignedImageCategory,
      onTagsMoved,
      confirmDelete,
      startAddCategory,
      clearAddCategory,
      AddImageCategoryFieldName,
      fieldIsError,
      fieldErrorMessage,
      addCategoryInput,
      isAdding,
      exportCategories,
      file,
      pickFile,
      fileInput,
      isImporting
    };
  }
});
</script>

<style lang="scss">

.o-image-categories-listing {
  margin: 20px;

  .m-pageHeader {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;

      &__info {
        margin: 4px;
      }
    }

    &__actions {
      display: flex;

      &__action {
        .a-addCategory__start {
          margin-left: 0px;
          margin-top: -4px;
        }
        margin-left: 8px;
        &.-import {
          height: 36px;
        }
        &.-export {
          height: 36px;
        }
        &.q-field--with-bottom {
          padding: 0px;
        }
      }
    }
  }

  &__table {

    .q-table__bottom.row.items-center.justify-end {
      justify-content: flex-start;
      padding-left: 8px;

      .q-table__separator {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
