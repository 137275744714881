import { ITagListState, ITagListStore, TAddTag, TClearTags, TDeselectDropTarget, TRemoveTag, TSetDropTarget, TStartDrag, TStopDrag } from '@/store/contracts/imageCategories/tagList';
import { App, inject, provide, reactive, readonly } from 'vue';

const TagListStoreKey = Symbol('TagListStore');

function addTag (state: ITagListState): TAddTag {
  return (tag) => {
    if (!state.selectedTags.includes(tag)) {
      state.selectedTags.push(tag);
    }
  };
}

function removeTag (state: ITagListState): TRemoveTag {
  return (tag) => {
    state.selectedTags = state.selectedTags.filter(t => t !== tag);
  };
}

function clearTags (state: ITagListState): TClearTags {
  return () => {
    state.selectedTags = [];
  };
}

function setDropTarget (state: ITagListState): TSetDropTarget {
  return (category) => {
    state.dropTargetCategory = category;
  };
}

function deselectDropTarget (state: ITagListState): TDeselectDropTarget {
  return (category) => {
    if (state.dropTargetCategory?.id === category.id) {
      setDropTarget(state)(null);
    }
  };
}

function startDrag (state: ITagListState): TStartDrag {
  return () => {
    // Because the user might just be clicking and accidentally dragging (trust me this is super easy to do)
    // We don't want to start our drag process right away.
    // Delay it by a fraction of a second. Then we know they're really dragging and not just clicking really fast.
    state.dragDelayTimerId = setTimeout(() => {
      state.isDragging = true;
      state.dragDelayTimerId = null;
    }, 250);
  };
}

function stopDrag (state: ITagListState): TStopDrag {
  return () => {
    // Cancel our delayed drag start if we're still within the delay.
    if (state.dragDelayTimerId !== null) {
      clearTimeout(state.dragDelayTimerId);
      state.dragDelayTimerId = null;
    }

    state.isDragging = false;
  };
}

export function createState (): ITagListState {
  return reactive<ITagListState>({
    selectedTags: [],
    dropTargetCategory: null,
    dragDelayTimerId: null,
    isDragging: false
  });
}

export function createForState (state: ITagListState): ITagListStore {
  return {
    state: readonly(state),
    addTag: addTag(state),
    removeTag: removeTag(state),
    clearTags: clearTags(state),
    setDropTarget: setDropTarget(state),
    deselectDropTarget: deselectDropTarget(state),
    startDrag: startDrag(state),
    stopDrag: stopDrag(state)
  };
}

export function provideStore (app?: App<Element>): ITagListStore {
  const state = createState();
  if (app !== undefined) {
    app.provide(TagListStoreKey, state);
  } else {
    provide(TagListStoreKey, state);
  }

  return createForState(state);
}

export function useStore (): ITagListStore {
  const state = inject<ITagListState>(TagListStoreKey);
  if (state === undefined) {
    throw new Error('Using TagListStore before providing it!');
  }
  return createForState(state);
}
